var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Fragment, useEffect, useState } from 'react';
import { Subject } from "rxjs";
import ExitIcon from "../../assets/feather/x-circle.svg";
import { ConfettiShow } from "../../app/Routes";
var afterLoad = false;
export var modalServiceSubject = new Subject();
export function showInternetProblem(e) {
    if (e === void 0) { e = null; }
    modalServiceSubject.next({
        title: 'Beklenmedik bir hata oluştur',
        content: e ? String(e) : 'Lütfen internet bağlantınız kontrol edip tekrar deneyin',
        onClose: function () {
            setTimeout(function () {
                window.location.reload();
            }, 2000);
        }
    });
}
var ModalService = function (props) {
    var _a = useState(false), showModal = _a[0], setShowModal = _a[1];
    useEffect(function () {
        var modalServiceSubject_subscription = modalServiceSubject.subscribe(function (obj) {
            if (obj) {
                setShowModal(obj);
                if (obj.timeout && obj.timeout > 0) {
                    setTimeout(function () {
                        setShowModal(null);
                    }, obj.timeout);
                }
            }
            else {
                unSetModal();
            }
        });
        return function () {
            modalServiceSubject_subscription.unsubscribe();
            afterLoad = false;
        };
    }, []);
    var unSetModal = function () {
        if (showModal && showModal.onClose) {
            showModal.onClose();
        }
        afterLoad = false;
        setShowModal(false);
    };
    if (showModal) {
        if (showModal.afterLoad && afterLoad === false) {
            afterLoad = true;
            setTimeout(function () {
                showModal.afterLoad();
            }, 5);
        }
        return (_jsxs(_Fragment, { children: [props.children, _jsxs("div", __assign({ className: "modal show", style: {
                        width: (showModal.width ? showModal.width : 'auto'),
                        maxWidth: (showModal.width ? 'calc( 100vw - 40px)' : '')
                    } }, { children: [showModal.title ? _jsxs("div", __assign({ className: "header justify-content-between d-flex" }, { children: [_jsx("div", { children: showModal.title }, void 0), showModal.closeIcon &&
                                    _jsx("div", { children: _jsx("img", { src: ExitIcon, width: 24, className: "float-right cursor-p invert-color", onClick: function () { return unSetModal(); } }, void 0) }, void 0)] }), void 0) : _jsx(_Fragment, {}, void 0), typeof (showModal.content) !== 'object' && _jsx("div", { className: "body", style: {
                                maxHeight: "calc(100vh - 150px)",
                                height: showModal.height ? showModal.height : "auto"
                            }, dangerouslySetInnerHTML: { __html: showModal.content } }, void 0), typeof (showModal.content) === 'object' && _jsx("div", __assign({ className: "body", style: { maxHeight: "calc(100vh - 150px)", height: showModal.height ? showModal.height : "auto" } }, { children: _jsx(Fragment, { children: showModal.content }, void 0) }), void 0), _jsx("div", __assign({ className: "footer p-2 ".concat(showModal.footerCenter ? 'text-center' : 'text-right') }, { children: showModal.confirm ? _jsxs(_Fragment, { children: [showModal.confirm.sure ? _jsx(_Fragment, { children: _jsx("button", __assign({ id: "modal-confirm-sure", className: "btn " + (showModal.confirm.sure.class || 'btn-primary'), onClick: function () {
                                                showModal.confirm.sure.action();
                                                unSetModal();
                                            } }, { children: showModal.confirm.sure.label }), void 0) }, void 0) : _jsx(_Fragment, {}, void 0), showModal.confirm.cancel ? _jsx(_Fragment, { children: _jsx("button", __assign({ className: "btn " + (showModal.confirm.cancel.class || 'btn-default'), onClick: function () {
                                                if (showModal.confirm.cancel && showModal.confirm.cancel.action) {
                                                    showModal.confirm.cancel.action();
                                                    unSetModal();
                                                }
                                                else {
                                                    unSetModal();
                                                }
                                            } }, { children: (showModal.confirm.cancel && showModal.confirm.cancel.label) ? showModal.confirm.cancel.label : 'Vazgeç' }), void 0) }, void 0) : _jsx(_Fragment, {}, void 0)] }, void 0) : _jsx(_Fragment, {}, void 0) }), void 0)] }), void 0), _jsx("div", { onClick: function () {
                        if (showModal.cancelBackdrop != true) {
                            unSetModal();
                        }
                    }, className: "modal-backdrop show" }, void 0), _jsx(ConfettiShow, {}, void 0)] }, void 0));
    }
    return (_jsx(_Fragment, { children: props.children }, void 0));
};
export default ModalService;
