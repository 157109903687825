var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Menu from '../help/widgets/menu';
import { useEffect } from 'react';
import Questions from "./widgets/questions";
import AllIddia from "./widgets/all.iddia";
import { HelpApi } from "./help.api";
import AlignLeft from '../../assets/feather/align-right.svg';
import { initialLeftMenu, sportsMenu } from "../../lib/misc.functions";
import { betSlipVisibleSubject } from "../../store/betslip.store";
import { useParams } from "react-router-dom";
import { allIddaaList } from '../../store/static.pages.store';
import AllGames from "./widgets/all.games";
var title = {
    "uyelik": "Üyelik",
    "para-yatirma": "Para Yatırma",
    "para-cekme": "Para Çekme",
    "iddaa-oyunlari": "Tüm İddaa Oyunları (Yeni Dönem)",
    "canli-iddaa": "Canlı İddaa",
    "iddaa": "İddaa",
    "spor-toto": "Spor Toto",
    // "ikramiyeler": "İkramiyeler",
    "sans-oyunlari": "Şans Oyunları"
};
var pages = {
    "uyelik": _jsx(Questions, {}, void 0),
    "para-yatirma": _jsx(Questions, {}, void 0),
    "para-cekme": _jsx(Questions, {}, void 0),
    "iddaa-oyunlari": _jsx(AllIddia, {}, void 0),
    "canli-iddaa": _jsx(Questions, {}, void 0),
    "iddaa": _jsx(Questions, {}, void 0),
    "spor-toto": _jsx(Questions, {}, void 0),
    // "ikramiyeler": "İkramiyeler",
    "sans-oyunlari": _jsx(AllGames, {}, void 0)
};
export default function Help() {
    var params = useParams();
    useEffect(function () {
        if (!allIddaaList.length) {
            HelpApi.alliIddaa().then(function (result) {
                allIddaaList.push.apply(allIddaaList, result);
            });
        }
        betSlipVisibleSubject.next(false);
    }, []);
    function menuSelected() {
        var element = document.getElementById("menuHamburger");
        if (element.style.display == "none") {
            element.style.display = "block";
        }
        else {
            element.style.display = "none";
        }
    }
    useEffect(function () {
        initialLeftMenu(false);
    }, [sportsMenu.isVisible]);
    return (_jsx("div", __assign({ className: "container-fluid page sosyoley" }, { children: _jsxs("div", __assign({ className: "row" }, { children: [_jsxs("div", __assign({ className: 'sub-menu visible-sm' }, { children: [_jsx("div", __assign({ className: "title" }, { children: title[params.page] }), void 0), _jsx("div", __assign({ className: "toggler", onClick: function () { return menuSelected(); } }, { children: _jsx("img", { className: "ico", src: AlignLeft, alt: "Sport Dallar\u0131" }, void 0) }), void 0), _jsx("div", __assign({ onClick: function () { return menuSelected(); }, className: "menu-body ", id: "menuHamburger", style: { display: "none" } }, { children: _jsx(Menu, {}, void 0) }), void 0)] }), void 0), _jsx("div", __assign({ className: "left-column" }, { children: _jsx(Menu, {}, void 0) }), void 0), _jsx("div", __assign({ className: "right-column", style: { marginTop: innerWidth <= 968 ? "30px" : "0px" } }, { children: pages[params.page] }), void 0)] }), void 0) }), void 0));
}
