var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { initialLeftMenu, slugify, sportsMenu } from "../../lib/misc.functions";
import { member, useAuthContext } from "../../store/login.store";
import { betSlipVisibleSubject } from "../../store/betslip.store";
import { gameList } from "../../store/static.pages.store";
import { ApiService } from "../../lib/http.service";
import { Storage } from "../../lib/localstorege.service";
import { env } from "../../definitions/global.vars";
function Game() {
    var iframeContainerRef = useRef(null);
    var isAuthenticated = useAuthContext().isAuthenticated;
    var navigate = useNavigate();
    var _a = useState(null), selectedGame = _a[0], setSelectedGame = _a[1];
    var token = Storage.get('token');
    var pathname = useLocation().pathname;
    useEffect(function () {
        initialLeftMenu(false);
        betSlipVisibleSubject.next(false);
    }, [sportsMenu.isVisible]);
    useEffect(function () {
        try {
            var gamePathname = pathname.split('/')[2];
            if (gameList.length > 0) {
                setSelectedGame(gameList.find(function (el) { return gamePathname == "".concat(slugify(el.code)); }));
            }
            else {
                var api = new ApiService();
                api.start("get", "".concat(env.core_api, "/games/api/list-game/"), {}, false, 60000)
                    .then(function (res) {
                    gameList.splice(0, gameList.length);
                    gameList.push.apply(gameList, res.data);
                    var _selectedGame = res.data.find(function (el) { return gamePathname == "".concat(slugify(el.code)); });
                    setSelectedGame(_selectedGame);
                });
            }
        }
        catch (e) {
            console.log(e);
        }
    }, [pathname]);
    if (isAuthenticated && selectedGame) {
        return (_jsx("div", { children: _jsx("iframe", { ref: iframeContainerRef, className: "iframeContainer", src: "".concat(env.website_url, "/assets/game-widget/game-widget.html?token=").concat(Storage.get("token"), "&vendor=").concat(selectedGame.vendor, "&code=").concat(selectedGame.code, "&userId=").concat(member.id, "&game_id=").concat(selectedGame.id) }, void 0) }, void 0));
    }
    else {
        return (_jsxs("div", __assign({ className: 'card', style: { marginTop: innerWidth <= 968 ? "25px" : "0px" } }, { children: [_jsx("h2", __assign({ className: "card-title my-5 text-center" }, { children: "\u015Eans oyunlar\u0131n\u0131 oynamak i\u00E7in giri\u015F yapmal\u0131s\u0131n\u0131z!" }), void 0), _jsx("div", __assign({ className: "card-body d-flex justify-content-center mb-5" }, { children: _jsx("button", __assign({ className: 'btn btn-warning btnLogin', onClick: function () { return navigate('/giris-yap'); } }, { children: "G\u0130R\u0130\u015E YAP" }), void 0) }), void 0)] }), void 0));
    }
}
export default Game;
