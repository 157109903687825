var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import BankAccountTableWidget from "../../widgets/mybank/bank.account.table.widget";
import AddBankAccount from '../../widgets/modal/add.bank.account';
export default function MyBankAccounts() {
    var _a = useState(false), isAdded = _a[0], setIsAdded = _a[1];
    return (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: "card" }, { children: [_jsx("div", __assign({ className: "card-title" }, { children: _jsx("h2", { children: "Banka Hesaplar\u0131m" }, void 0) }), void 0), _jsxs("div", __assign({ className: "card-body" }, { children: [_jsx(BankAccountTableWidget, { isAdded: isAdded }, void 0), _jsx("div", __assign({ className: "mt-2 text-justify table" }, { children: _jsx("span", { children: "Sadece ad\u0131n\u0131za a\u00E7\u0131lm\u0131\u015F vadesiz mevduat hesaplar\u0131na para aktarabilirsiniz. T\u00FCrkiye \u0130\u015F Bankas\u0131'na en az 20 TL di\u011Fer bankalara en az 10 TL i\u00E7in talep verebilirsiniz. TC kimlik numaran\u0131z d\u0131\u015F\u0131nda bir banka hesab\u0131na para \u00E7ekim talebinde bulundu\u011Funuzda \u00E7ekim ger\u00E7ekle\u015Fmeyecektir." }, void 0) }), void 0), _jsx(AddBankAccount, { setIsAdded: setIsAdded, isAdded: isAdded }, void 0)] }), void 0)] }), void 0), _jsx("br", {}, void 0)] }, void 0));
}
