var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import "./betslip.css";
import Trash from "../../assets/feather/trash-2.svg";
import Save from "../../assets/feather/save.svg";
import AlertCircle from "../../assets/feather/alert-circle.svg";
import CheckCircle from "../../assets/feather/check-circle.svg";
import Clock from "../../assets/feather/clock.svg";
import { location, member } from "../../store/login.store";
import { betSlipActionSubject, BetSlipList, BetSlipOption, betSlipVisibleSubject, } from "../../store/betslip.store";
import { BetSlipItem } from "./components/item";
import _ from "lodash";
import { clearBetSlip, updateSystemOptions, setSystemChoice, combinationCalculation, setBetSlipQuantity, setBetSlipMultiply, setBetSlipConditions, playCoupon, setUsePointBalance, betSlipMaxMultiply, addToBetSlip } from "../../services/betslip.services";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { eventMapping, eventUpdateSubject, marketMapping } from "../../store/sportsbook.store";
import { useForceUpdate } from "../../lib/misc.functions";
import { eventDetailVisiblity } from "../../pages/sportsbook/eventdetail";
import iconBetSlip from "../../assets/img/betslip-icon.png";
import { Location } from '../../models/user.model';
import iconCustomRatio from "../../assets/img/fire.gif";
import { ApiService } from "../../lib/http.service";
import { Storage } from "../../lib/localstorege.service";
import { env } from "../../definitions/global.vars";
export var betSlipVisiblity = false;
var maxQuantity = 100;
var betslipRecalcTimer = null;
var maxRatioPoint = null;
export default function BetSlipComponent() {
    var forceUpdate = useForceUpdate();
    //fixme : kullanıcı bazlı kısıtlama gelmeli
    var quantityChoices = _.range(1, 101);
    if (member && member.id) {
        if (member.max_rank && member.max_rank <= 50) {
            quantityChoices.push.apply(quantityChoices, _.range(200, 501, 50));
            maxQuantity = 100;
        }
    }
    var multiplyChoices = _.range(1, 101);
    multiplyChoices.push.apply(multiplyChoices, _.range(200, betSlipMaxMultiply + 1, 50));
    var width = window.innerWidth;
    var _a = useState({ betSlipList: BetSlipList, betSlipOptions: BetSlipOption }), betSlipContext = _a[0], setBetSlipContext = _a[1];
    var _b = useState(width > 969), betSlipVisible = _b[0], setBetSlipVisible = _b[1];
    var betslipAdditionalRatio = 0;
    betSlipContext.betSlipList.forEach(function (i) {
        if (i.outcome.customOdds) {
            var addRatio = Number(i.outcome.fixedOdds) - Number(i.outcome.fixedOddsWeb);
            if (betslipAdditionalRatio < addRatio) {
                betslipAdditionalRatio = Number(addRatio.toFixed(2));
            }
        }
    });
    function onLocation() {
        var object = new Location();
        object.path = "back";
        Object.assign(location, object);
    }
    function bodyVisible() {
        if (betSlipVisible === false) {
            setBetSlipVisible(true);
        }
        else {
            setBetSlipVisible(false);
        }
    }
    if (betSlipVisible && width < 969) {
        document.body.style.overflow = 'hidden';
    }
    else if (eventDetailVisiblity.visible === false) {
        document.body.style.overflow = 'unset';
    }
    useEffect(function () {
        var betSlipVisibleSubject_subject = betSlipVisibleSubject.subscribe(function (state) {
            setBetSlipVisible(state);
            betSlipVisiblity = state;
        });
        return function () {
            betSlipVisibleSubject_subject.unsubscribe();
        };
    }, []);
    useEffect(function () {
        var eventUpdateSubject_subscription = eventUpdateSubject.subscribe(function (events) {
            if (BetSlipOption.inProgress === false) {
                var intersections = betSlipContext.betSlipList.filter(function (el) {
                    if (events.includes(el.sportEvent.eventId)) {
                        return el.sportEvent.eventId;
                    }
                });
                if (intersections.length > 0) {
                    clearTimeout(betslipRecalcTimer);
                    betslipRecalcTimer = setTimeout(function () {
                        updateSystemOptions();
                        combinationCalculation();
                        setBetSlipContext({
                            betSlipList: BetSlipList,
                            betSlipOptions: BetSlipOption,
                        });
                        forceUpdate();
                    }, 300);
                }
            }
        });
        return function () {
            clearTimeout(betslipRecalcTimer);
            eventUpdateSubject_subscription.unsubscribe();
        };
    }, []);
    useEffect(function () {
        // console.log("betSlipActionSubject_subscription>>>>>>>")
        var betSlipActionSubject_subscription = betSlipActionSubject.subscribe(function () {
            updateSystemOptions();
            combinationCalculation();
            setBetSlipContext({
                betSlipList: BetSlipList,
                betSlipOptions: BetSlipOption,
            });
            if (BetSlipOption.bettingInfoStatus === 1) {
                countDown(BetSlipOption.delay);
            }
        });
        return function () {
            betSlipActionSubject_subscription.unsubscribe();
        };
    }, []);
    useEffect(function () {
        if (member.id) {
            var cachedPoint = Storage.get('cached_available_bonus');
            if (cachedPoint) {
                maxRatioPoint = Number(cachedPoint);
                if (maxRatioPoint !== Number(cachedPoint)) {
                    forceUpdate();
                }
            }
            else {
                var api = new ApiService();
                api.start('post', env.core_api + '/marketing/api/available-bonus/', Storage.get('token') ? { token: Storage.get('token') } : null, true, 3000)
                    .then(function (res) {
                    Storage.set('cached_available_bonus', res.data.available_bonus, 60 * 1000);
                    maxRatioPoint = Number(res.data.available_bonus);
                    forceUpdate();
                });
            }
        }
    }, [betSlipContext.betSlipOptions.betSlipMultiply, betSlipContext.betSlipOptions.betSlipQuantity]);
    var countDown = function (s) {
        var divCountDown = document.getElementById('divCountDown');
        if (divCountDown) {
            divCountDown.innerText = 'Onay bekliyor...';
            // divCountDown.innerText = String(s) + ' saniye...';
            if (s > 0) {
                setTimeout(function () {
                    countDown(s -= 1);
                }, 1300);
            }
        }
    };
    if (betSlipVisible && betSlipContext && betSlipContext.hasOwnProperty('betSlipList')) {
        var couponColumn = betSlipContext.betSlipOptions.systemCombinations.length;
        var couponCondition = betSlipContext.betSlipOptions.couponCondition;
        var couponTotalRatio = betSlipContext.betSlipOptions.totalRatio;
        var betSlipMultiply = betSlipContext.betSlipOptions.betSlipMultiply;
        var betSlipQuantity = betSlipContext.betSlipOptions.betSlipQuantity;
        var couponAmount = couponColumn * betSlipMultiply * betSlipQuantity;
        var diffProfit = betslipAdditionalRatio * betSlipMultiply * betSlipQuantity;
        if (maxRatioPoint !== null && maxRatioPoint === 0) {
            betslipAdditionalRatio = 0;
        }
        var maxProfit = (couponTotalRatio + betslipAdditionalRatio) * betSlipMultiply * betSlipQuantity;
        if (maxRatioPoint !== null && diffProfit > maxRatioPoint) {
            diffProfit = maxRatioPoint;
            maxProfit = (couponTotalRatio * betSlipMultiply * betSlipQuantity) + diffProfit;
        }
        if (betSlipContext.betSlipOptions.bettingInfoMessage !== null) {
            return (_jsxs("div", __assign({ className: "betslip-wrapper" }, { children: [_jsxs("div", __assign({ className: "header" }, { children: [!betSlipContext.betSlipOptions.bettingInfoStatus || betSlipContext.betSlipOptions.bettingInfoStatus === 0 ? 'Kupon oynanıyor...' : '', betSlipContext.betSlipOptions.bettingInfoStatus === 1 ? 'Kupon oynanıyor...' : '', betSlipContext.betSlipOptions.bettingInfoStatus === 2 ? 'MBS onaylamadı' : '', betSlipContext.betSlipOptions.bettingInfoStatus === 3 ? 'Kupon oynandı' : '', betSlipContext.betSlipOptions.bettingInfoStatus === 1 ? _jsx("span", { children: "Bekleyiniz" }, void 0) : _jsx(_Fragment, {}, void 0)] }), void 0), _jsxs("div", __assign({ className: "text-center mt-5 p-2" }, { children: [betSlipContext.betSlipOptions.bettingInfoStatus === 1 ?
                                _jsx("p", __assign({ className: "text-center" }, { children: _jsx("img", { className: "rotationImage", src: Clock, width: 50 }, void 0) }), void 0) : '', betSlipContext.betSlipOptions.bettingInfoStatus === 2 ?
                                _jsx("p", __assign({ className: "text-center" }, { children: _jsx("img", { src: AlertCircle, width: 50 }, void 0) }), void 0) : '', betSlipContext.betSlipOptions.bettingInfoStatus === 3 ?
                                _jsx("p", __assign({ className: "text-center" }, { children: _jsx("img", { src: CheckCircle, width: 50 }, void 0) }), void 0) : '', _jsx("p", { className: "text-center", dangerouslySetInnerHTML: { __html: betSlipContext.betSlipOptions.bettingInfoMessage } }, void 0), betSlipContext.betSlipOptions.bettingInfoStatus === 1 ?
                                _jsx("div", { id: "divCountDown", className: "text-center fw-bold" }, void 0) : ''] }), void 0)] }), void 0));
        }
        if (betSlipContext.betSlipList.length === 0) {
            return (_jsxs("div", __assign({ className: "betslip-wrapper" }, { children: [_jsxs("div", __assign({ className: "header", onClick: function () { return bodyVisible(); } }, { children: ["Kuponum ", _jsx("i", __assign({ className: "badge" }, { children: betSlipContext.betSlipList.length }), void 0), _jsxs("span", { children: ["Oran: ", couponTotalRatio.toFixed(2)] }, void 0)] }), void 0), _jsx("div", __assign({ className: "text-center mt-5" }, { children: _jsxs("p", { children: ["Kuponunuzda ma\u00E7 bulunmamaktad\u0131r.", _jsx("br", {}, void 0), "Ma\u00E7 eklemek i\u00E7in oranlar\u0131n \u00FCzerine t\u0131klayabilirsiniz"] }, void 0) }), void 0)] }), void 0));
        }
        return (_jsx(_Fragment, { children: _jsxs("div", __assign({ id: "betslip", className: "betslip-wrapper" }, { children: [_jsxs("div", __assign({ className: "header" }, { children: [_jsxs("div", __assign({ className: "float-left w-50 nowrap" }, { children: ["Kuponum ", _jsx("i", __assign({ className: "badge" }, { children: betSlipContext.betSlipList.length }), void 0)] }), void 0), _jsxs("div", __assign({ className: "float-right text-right w-50 nowrap" }, { children: ["Oran: ", couponTotalRatio.toFixed(2), betSlipVisible === 'toggle' ? _jsx("div", __assign({ className: "btn btn-small", style: { padding: '1px 6px' }, onClick: function () { return bodyVisible(); } }, { children: "X" }), void 0) : _jsx(_Fragment, {}, void 0)] }), void 0)] }), void 0), _jsxs("div", { children: [_jsx("div", { className: "clearfix" }, void 0), _jsx("div", __assign({ id: "betslip-items", className: "betslip-items" }, { children: betSlipContext.betSlipList.map(function (item, key) {
                                    return _jsx("div", __assign({ className: "event-card list" }, { children: _jsx(BetSlipItem, { item: item }, void 0) }), key);
                                }) }), void 0), _jsxs("div", __assign({ className: "row group" }, { children: [_jsx("div", __assign({ className: "col-7 fw-bold" }, { children: "Sistem:" }), void 0), _jsxs("div", __assign({ className: "col-5 fw-bold text-right" }, { children: ["Kolon: ", betSlipContext.betSlipOptions.systemCombinations.length] }), void 0), _jsx("div", __assign({ className: "col-12" }, { children: betSlipContext.betSlipOptions.systemOptions ? betSlipContext.betSlipOptions.systemOptions.map(function (item, key) {
                                            return (_jsx("button", __assign({ onClick: function () {
                                                    setSystemChoice(item);
                                                }, className: "systemChoices " + (item.sysSelected ? 'selected' : '') }, { children: item.sysNum }), key));
                                        }) : '' }), void 0)] }), void 0), _jsxs("div", __assign({ className: "row group" }, { children: [_jsxs("div", __assign({ className: "col-6" }, { children: [_jsx("span", __assign({ className: "fw-bold" }, { children: "Kupon adedi:" }), void 0), _jsx("input", { className: "select betslip-input", min: 1, max: maxQuantity, step: 1, value: BetSlipOption.betSlipQuantity || '', onChange: function (e) { return setBetSlipQuantity(Number(e.target.value)); }, type: "number" }, void 0), _jsx("select", __assign({ className: "betslip-select", value: betSlipQuantity, onChange: function (e) { return setBetSlipQuantity(Number(e.target.value)); } }, { children: quantityChoices.map(function (el, key) {
                                                    return _jsx("option", __assign({ value: el }, { children: el }), key);
                                                }) }), void 0)] }), void 0), _jsxs("div", __assign({ className: "col-6" }, { children: [_jsx("span", __assign({ className: "fw-bold" }, { children: "Misli:" }), void 0), _jsx("input", { className: "select betslip-input", type: "text", pattern: "^[0-9\\b]+$", min: 1, max: betSlipMaxMultiply, step: 1, value: BetSlipOption.betSlipMultiply || '', onChange: function (e) { return setBetSlipMultiply(Number(e.target.value)); }, onPaste: function (e) {
                                                    e.preventDefault();
                                                    return false;
                                                } }, void 0), _jsx("select", __assign({ className: "betslip-select", value: betSlipMultiply, onChange: function (e) { return setBetSlipMultiply(Number(e.target.value)); } }, { children: multiplyChoices.map(function (el, key) {
                                                    return _jsx("option", __assign({ value: el }, { children: el }), key);
                                                }) }), void 0)] }), void 0)] }), void 0), _jsx("div", __assign({ className: "m-6 group" }, { children: _jsxs("div", __assign({ className: "px-2" }, { children: [_jsx("div", __assign({ className: "w-50 float-left" }, { children: "Kupon bedeli" }), void 0), _jsxs("div", __assign({ className: "w-50 float-right text-right fw-bold" }, { children: [couponAmount ? couponAmount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,').replace(",", "!").replace(".", ",").replace("!", ".") : '0,00', "\u20BA"] }), void 0), _jsx("div", __assign({ className: "w-50 float-left" }, { children: "Toplam Oran" }), void 0), _jsx("div", __assign({ className: "w-50 float-right text-right  fw-bold" }, { children: betslipAdditionalRatio > 0 ? _jsxs(_Fragment, { children: [_jsx("img", { src: iconCustomRatio, className: "rapidIcon", style: { marginTop: -5, marginRight: 5 } }, void 0), _jsx("span", __assign({ style: {
                                                            textDecoration: 'line-through',
                                                            fontWeight: 400,
                                                            paddingRight: '5px'
                                                        } }, { children: couponTotalRatio.toFixed(2) }), void 0), (couponTotalRatio + betslipAdditionalRatio).toFixed(2)] }, void 0) : _jsx(_Fragment, { children: (couponTotalRatio + betslipAdditionalRatio).toFixed(2) }, void 0) }), void 0), _jsx("div", __assign({ className: "w-50 float-left" }, { children: "Max Kazan\u00E7" }), void 0), _jsxs("div", __assign({ className: "w-50 float-right text-right  fw-bold" }, { children: [maxProfit <= 12500000 * betSlipQuantity ? maxProfit.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,').replace(",", "!").replace(".", ",").replace("!", ".") : (12500000 * betSlipQuantity).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,').replace(",", "!").replace(".", ",").replace("!", "."), "\u20BA"] }), void 0), _jsx("div", { className: "clearfix" }, void 0), betslipAdditionalRatio > 0 && maxRatioPoint && maxRatioPoint > 0 ? _jsx(_Fragment, { children: _jsxs("div", __assign({ style: {
                                                    position: 'relative',
                                                    borderRadius: 8,
                                                    overflow: 'hidden',
                                                    marginTop: '7px'
                                                } }, { children: [_jsx("div", { className: "customRatioContainer" }, void 0), _jsxs("div", __assign({ className: "blink_me", style: {
                                                            zIndex: 1,
                                                            position: 'relative',
                                                            padding: '5px',
                                                            fontSize: '12px',
                                                            fontWeight: 500,
                                                            textAlign: 'center',
                                                            borderRadius: '5px'
                                                        } }, { children: ["Oley.com bu kupon i\u00E7in size ", _jsxs("b", { children: [diffProfit ? diffProfit.toFixed(2) : '0,00', "\u20BA"] }, void 0), " ekstra kazan\u00E7 sa\u011Flamaktad\u0131r"] }), void 0)] }), void 0) }, void 0) : _jsx(_Fragment, {}, void 0)] }), void 0) }), void 0), _jsx("div", __assign({ className: "group px-2" }, { children: _jsxs("select", __assign({ value: couponCondition, onChange: function (e) { return setBetSlipConditions(e.target.value); } }, { children: [_jsx("option", __assign({ value: "1" }, { children: "T\u00FCm oran de\u011Fi\u015Fimlerini kabul et" }), void 0), _jsx("option", __assign({ value: "2" }, { children: "Sadece y\u00FCkselen oranlar\u0131 kabul et" }), void 0), _jsx("option", __assign({ value: "3" }, { children: "Oran de\u011Fi\u015Fikliklerini kabul etme" }), void 0)] }), void 0) }), void 0), member.id ? _jsx(_Fragment, { children: _jsxs("div", __assign({ className: "row group" }, { children: [_jsxs("div", __assign({ className: "col-6" }, { children: [_jsx("div", __assign({ className: "fw-bold" }, { children: "Puan Kullan:" }), void 0), _jsxs("label", __assign({ className: "m-0" }, { children: [_jsx("input", { type: "checkbox", checked: BetSlipOption.usePointBalance, onChange: function () {
                                                                setUsePointBalance(!BetSlipOption.usePointBalance);
                                                            } }, void 0), " ", member ? (member.point_balance / 100).toFixed(2) + '₺' : '0.00₺'] }), void 0)] }), void 0), _jsxs("div", __assign({ className: "col-6" }, { children: [_jsx("div", __assign({ className: "fw-bold" }, { children: "Se\u00E7imlerimi:" }), void 0), _jsxs("label", __assign({ className: "m-0" }, { children: [_jsx("input", { type: "checkbox", value: "1" }, void 0), " Kaydet"] }), void 0)] }), void 0)] }), void 0) }, void 0) : _jsx(_Fragment, {}, void 0), (maxProfit / betSlipQuantity) > 12500000 ? (_jsx("div", __assign({ className: "m-6 alert-danger" }, { children: "Bir kupondan kazanabilece\u011Finiz maksimum tutar 12.500.000 TL olabilir." }), void 0)) : _jsx(_Fragment, {}, void 0), BetSlipOption.errorMessage ? (_jsx("div", __assign({ className: "m-6 alert-danger" }, { children: BetSlipOption.errorMessage }), void 0)) : _jsx(_Fragment, {}, void 0), _jsx("div", __assign({ className: "footer" }, { children: _jsxs("div", __assign({ className: "row m-0 ml-n1  mr-n1" }, { children: [_jsx("div", __assign({ className: "w-25 p-1" }, { children: _jsx("button", __assign({ onClick: function () {
                                                    clearBetSlip();
                                                }, className: "btn btn-warning w-100 m-0" }, { children: _jsx("img", { src: Trash, width: "16" }, void 0) }), void 0) }), void 0), _jsx("div", __assign({ className: "w-25 p-1" }, { children: member.id ? _jsx(_Fragment, { children: _jsx("button", __assign({ disabled: BetSlipOption.inProgress, onClick: function () {
                                                        playCoupon(true);
                                                    }, className: "btn w-100 m-0" }, { children: _jsx("img", { src: Save, width: "16" }, void 0) }), void 0) }, void 0) : _jsx(_Fragment, { children: _jsx("div", __assign({ onClick: function () { return onLocation(); } }, { children: _jsx(Link, __assign({ to: "giris-yap", className: "btn w-100  m-0" }, { children: _jsx("img", { src: Save, width: "16" }, void 0) }), void 0) }), void 0) }, void 0) }), void 0), _jsx("div", __assign({ className: "w-50 p-1" }, { children: member.id ? _jsx(_Fragment, { children: _jsx("button", __assign({ disabled: BetSlipOption.inProgress, onClick: function () {
                                                        playCoupon(false);
                                                    }, className: "btn w-100  m-0 btn-primary" }, { children: "Oyna" }), void 0) }, void 0) : _jsx(_Fragment, { children: _jsx("div", __assign({ onClick: function () { return onLocation(); } }, { children: _jsx(Link, __assign({ to: "giris-yap", onClick: function () { return bodyVisible(); }, className: "btn w-100  m-0 btn-primary" }, { children: "Oyna" }), void 0) }), void 0) }, void 0) }), void 0)] }), void 0) }), void 0)] }, void 0)] }), void 0) }, void 0));
    }
    else {
        if (BetSlipList.length > 0) {
            return (_jsx(_Fragment, { children: _jsx("div", __assign({ className: "betslip-toggler", onClick: function () {
                        betSlipVisibleSubject.next('toggle');
                    } }, { children: _jsxs("a", __assign({ id: "leftSportMenu-btn" }, { children: [_jsxs("span", { children: [BetSlipList.length, " ma\u00E7"] }, void 0), _jsx("span", { children: _jsx("img", { src: iconBetSlip }, void 0) }, void 0), _jsx("span", __assign({ className: "nowrap" }, { children: BetSlipOption.totalRatio.toFixed(2) }), void 0)] }), void 0) }), void 0) }, void 0));
        }
        else {
            return _jsx(_Fragment, {}, void 0);
        }
    }
}
export function AddToCartService() {
    var navigate = useNavigate();
    var params = useSearchParams()[0];
    console.log(params.get('e'));
    useEffect(function () {
        var _a;
        if (params.get('e') !== null) {
            var oddInfo_1 = (_a = params.get('e')) === null || _a === void 0 ? void 0 : _a.split('-');
            var eventObj = eventMapping[String(oddInfo_1[0])];
            var marketObj = void 0;
            var outcomeObj = void 0;
            if (eventObj) {
                marketObj = marketMapping[String(oddInfo_1[0])].find(function (m) { return Number(m.marketId) === Number(oddInfo_1[1]); });
                if (marketObj) {
                    outcomeObj = marketObj.marketOutcome.find(function (el) { return Number(el.outcomeNo) === Number(oddInfo_1[2]); });
                }
            }
            if (eventObj && marketObj && outcomeObj) {
                addToBetSlip(eventObj, marketObj, outcomeObj, 'add');
                betSlipVisibleSubject.next(true);
            }
            if (eventObj) {
                navigate("/iddaa/".concat(eventObj.sportSlug, "/tumu/").concat(eventObj.competitionId, "/").concat(eventObj.eventId, "/"));
            }
            else {
                navigate("/iddaa/futbol/tumu/");
            }
        }
        else {
            navigate('/');
        }
    }, [params.get('e')]);
    return (_jsx(_Fragment, {}, void 0));
}
